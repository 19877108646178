// src/utils/errorHandler.ts

export interface Toast {
  error(message: string): void;
}

export interface ApiError {
  response?: {
    data?: {
      message?: string[];
    };
  };
}

/**
 * APIエラーをハンドリングする関数
 * @param error - API呼び出しで発生したエラー
 * @param deps - 利用する依存オブジェクト（例: { $toast }）
 */
export function handleApiError(
  error: ApiError,
  { $toast }: { $toast: Toast },
): void {
  const messages = error.response?.data?.message

  if (messages && messages.length > 0) {
    messages.forEach(msg => $toast.error(msg))

    return
  }

  $toast.error('予期しないエラーが発生しました。')
}
